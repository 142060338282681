<template>
  <section class="navbar-free">
    <Transition name="slide-fade">
      <div v-if="isMenuOpen" class="navbar-free__overlay" @click="isMenuOpen = false">
        <div class="navbar-free__overlay__lateral-options">
          <div v-if="isLoggedIn" class="navbar-free__overlay__lateral-options__user">
            <span>
              <img src="/icons/icn_user_v2.svg" alt="user" />
            </span>
            <span>{{ user.name }}</span>
          </div>
          <div v-else class="navbar-free__overlay__lateral-options__new-user">
            <a class="btn btn-login" @click="onClickLogin">
              <span>
                Iniciar Sesión
              </span>
              <span>
                <BIcon icon="account-outline" size="is-medium"> </BIcon>
              </span>
            </a>
            <a class="btn btn-create-account" @click="onClickRegister">
              Crear cuenta Premium
              <div>
                <div>$ <span class="amount">99</span> al mes</div>
              </div>
            </a>
          </div>
          <div class="navbar-free__overlay__lateral-options__items general-options">
            <template v-for="(option, index) in availableMenuOptions">
              <div
                v-if="option.isB2C ? isB2CUser : true"
                :key="index"
                class="option"
                @click="onClickOption(option.sectionRef, option.route)"
              >
                <div class="logo">
                  <img :src="option.logo" :alt="option.title" />
                </div>
                <div class="title">{{ option.title }}</div>
              </div>
            </template>
            <div v-if="isLoggedIn" @click.prevent.stop="onClickLogout" class="option">
              <div class="logo">
                <img src="/icons/icn_sign_out.svg" alt="signout" />
              </div>
              <div class="title">Cerrar Sesión</div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <nav class="navbar-free__nav" :style="{ background: custom.headerBackground }">
      <RouterLink class="navbar-free__nav__brand" :to="logoLink">
        <template v-if="custom.team_id == 0">
          <img
            :src="
              displayWidth > 768 ? '/images/logo_golstats_horizontal_white.svg' : '/images/logo-golstats-shield.svg'
            "
            alt="GolStats"
          />
        </template>
        <template v-else>
          <img
            :src="
              displayWidth > 768
                ? custom.logo == 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png'
                  ? '/images/logo_golstats_horizontal_white.svg'
                  : custom.logo
                : custom.logo
            "
            alt="GolStats"
            :class="displayWidth < 320 ? 'logo-team-flag' : ''"
          />
        </template>
      </RouterLink>
      <div v-if="!isLoggedIn" class="navbar-free__nav__options">
        <a class="navbar-free__nav__options__create-account is-uppercase" @click="onClickRegister">
          Crear Cuenta
          <div>
            <div class="is-lowercase">$ <span class="amount">99</span> al mes</div>
          </div>
        </a>
        <a class="is-uppercase" @click="onClickLogin">Iniciar Sesión</a>
      </div>
      <div v-if="isLoggedIn" class="navbar-free__nav__menu" @click="onMenuClicked">
        <div class="navbar-free__nav__menu__text">Menu</div>
        <div class="navbar-free__nav__menu__hamburger">
          <div />
          <div />
          <div />
        </div>
      </div>
    </nav>
    <div class="navbar-free__stadium" v-if="isStadiumVisible">
      <div class="navbar-free__stadium__header" :style="{ background: custom.headerBackground }">
        <div>
          <RouterLink class="navbar-free__stadium__header__brand" :to="{ name: 'home' }">
            <img src="/images/logo_golstats_horizontal_white.svg" alt="GolStats" />
          </RouterLink>
        </div>
      </div>
      <div class="navbar-free__stadium__image">
        <h4>Estadísticas <span>GolStats</span></h4>
        <img src="/images/logo-golstats-shield.svg" alt="Logo" />
      </div>
    </div>
    <Confirm
      question="¿Estás seguro que deseas cerrar tu sesión?"
      :showModalConfirm="showModalConfirm"
      @onConfirm="logout"
      @onCancel="closeModalConfirm"
    />
  </section>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import router from '@/router';
import Confirm from '@/components/Modals/Confirm';
import { ProfileSidebarGoTo, clickCrearCuentaPremium, clickLogin, clickBtnLogout } from '@/utils/analytics';

export default {
  name: 'NavBarFree',
  components: {
    Confirm,
  },
  props: {
    isStadiumVisible: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Object,
      default: () => {
        return {
          logo: 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png',
          color: '#FFFFFF',
          headerBackground: '#242424',
          headerColor: '#FFFFFF',
          headerBorderColor: '##6EB913',
          header2Background: '#3b3b3b',
          header2Color: '#FFFFFF',
          subHeaderBackground: '#cce8b5',
          subHeaderColor: '#353535',
          type: 1,
          team_id: 0,
          tournaments: false,
          categories: 'all',
        };
      },
    },
    widgetTeam: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [{ title: 'Ajustes', icon: '/icons/icn-ajustes.svg', to: 'payment', event: 'click_btn_profile' }],
      itemsNav: [
        { title: 'Home', icon: '/icons/icn_home.svg', to: 'widget', event: 'home' },
        // { title: 'Contáctanos', icon: '/icons/icn-contactanos.svg', to: 'contact', event: 'contactanos' },
      ],
      selected: null,
      showModalConfirm: false,
      isMenuOpen: false,
      menuOptions: [
        {
          title: 'Ajustes',
          logo: '/icons/icn-ajustes.svg',
          route: { to: 'payment' },
          isB2C: true,
        },
        /*{
          title: 'Datos GS',
          logo: '/images/menu/icn-datos-gs@2x.png',
          sectionRef: 'datosGS',
        },
        {
          title: 'Apostador',
          logo: '/images/menu/icn-apostadores@2x.png',
          sectionRef: 'apostadorGS',
        },
        {
          title: 'Mejores Jugadores',
          logo: '/images/menu/icn-mejores-jugadores@2x.png',
          sectionRef: 'mejoresGS',
        },
        {
          title: 'Calendario',
          logo: '/images/menu/icn-previas@2x.png',
          sectionRef: 'previasGS',
        },
        {
          title: 'LIVE',
          logo: '/images/menu/icn_live.svg',
          sectionRef: 'liveGS',
        },
        {
          title: 'Tabla General',
          logo: '/images/menu/icn-tabla-general@2x.png',
          sectionRef: 'tablaGS',
        },*/
      ],
    };
  },
  computed: {
    ...mapState('profileGeneral', ['colorsConfig']),
    ...mapState(['isSectionLoading', 'displayWidth']),
    ...mapGetters('loginGeneral', ['isB2CUser', 'isLoggedIn', 'getPremiumAccount', 'getIsGolstats']),
    ...mapState('loginGeneral', ['user', 'pymErr']),
    ...mapGetters('general', ['getSectionAnalytics']),
    availableMenuOptions() {
      if (this.$route.meta.isMenuHidden || (this.$route.name === 'widget' && this.colorsConfig.type === 13)) {
        return [this.menuOptions[0]];
      } else {
        return this.menuOptions;
      }
    },
    showNav() {
      return this.displayWidth < 1210;
    },
    logoLink() {
      const stringWidgetTeam = this.widgetTeam || localStorage.getItem('widget-team');
      return stringWidgetTeam
        ? {
            name: 'widget',
            params: {
              team: this.widgetTeam || localStorage.getItem('widget-team'),
            },
          }
        : {
            name: 'viewAll',
          };
    },
  },
  methods: {
    ...mapMutations(['SET_MENU_DRAWER', 'setIsSectionLoading']),
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapActions('loginGeneral', ['doLogout']),
    ...mapMutations('general', ['setSectionAnalytics', 'setSectionRef']),
    closeModalConfirm() {
      this.showModalConfirm = false;
    },
    async logout() {
      this.setIsSectionLoading(true);
      try {
        await this.doLogout({ is3pvLogout: true });
        // if (this.$route.path.startsWith('/widget/')) {
        //   this.$router.go();
        // }
        // todo: check if this call is required
        // await this.fetchTournamentsWithScenarios(1);
      } finally {
        this.isMenuOpen = false;
        this.setIsSectionLoading(false);
      }
    },
    goTo(route) {
      ProfileSidebarGoTo(route.event, this.getPremiumAccount, 'menu_lateral', this.getIsGolstats);
      if (route.to !== router.currentRoute.name) {
        const location = { name: route.to };
        if (route.to === 'widget') {
          location.params = { team: localStorage.getItem('widget-team') };
        }
        router.push(location);
      }
    },
    onClickRegister() {
      if (this.$route.name === 'playerprofile') this.setSectionAnalytics('Banner_PlayerProfile');
      else if (this.$route.name === 'teamprofile') this.setSectionAnalytics('Banner_TeamProfile');
      else this.setSectionAnalytics('Banner_Home_Widget');
      clickCrearCuentaPremium({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        section: 'menu_lateral',
        banner: this.getSectionAnalytics,
      });
      this.SET_AUTH_COMPONENT('SelectPlan');
    },
    onClickLogin() {
      this.setSectionAnalytics('Banner_Home_Widget');
      clickLogin({ is_premium: this.getPremiumAccount, section: 'menu_lateral', golstats: this.getIsGolstats });
      this.SET_AUTH_COMPONENT('Login');
    },
    onClickOption(sectionRef, route) {
      if (sectionRef) {
        this.setSectionRef(sectionRef);
      } else if (route) {
        this.goTo(route);
      }
    },
    onClickLogout() {
      clickBtnLogout(this.getPremiumAccount, this.getIsGolstats);
      this.showModalConfirm = true;
    },
    onMenuClicked() {
      this.isMenuOpen = !this.isMenuOpen;
      // this.$router.push({ name: 'payment-methods' });
    },
  },
};
</script>

<style scoped lang="scss">
$backgroundColor: #242424;
$borderColor: #e0e0e0;
.navbar-free {
  width: 100%;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    padding-top: 61px;
    overflow: auto;
    transition: width ease 0.5s;

    &__lateral-options {
      width: 300px;
      min-height: 100%;
      height: fit-content;
      background-color: white;

      &__user {
        font-family: Circular-Std-Bold;
        font-size: 2rem;
        letter-spacing: -0.17px;
        color: #494a4b;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-bottom: solid 1px $borderColor;

        & span:first-child {
          margin-right: 16px;
          width: 50px;
        }

        & span:last-child {
          text-overflow: ellipsis;
        }
      }

      &__new-user {
        padding: 1rem 2.5rem;
        border-bottom: solid 1px $borderColor;
      }

      &__items {
        width: 100%;
        display: flex;
        padding: 0 55px;
        flex-direction: column;
        align-items: center;

        &.general-options {
          padding: 0 1.2rem;

          & div.option {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px dotted #cfdce2;
            cursor: pointer;

            &:last-child {
              border-bottom: unset;
            }

            & .title {
              margin: 1.875rem 0;
              font-family: Roboto-Medium, sans-serif;
              font-size: 1.25rem;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 0.9;
              letter-spacing: 0.2px;
              text-align: left;
              color: #132839;
            }

            & .logo {
              height: 2rem;
              width: auto;
              margin: 0 0.8rem;

              & > img {
                height: 2rem;
                width: auto;
              }
            }
          }
        }

        & div.item {
          width: 100%;
          cursor: pointer;
          padding: 15px;
          font-size: 1.125rem;
          letter-spacing: -0.1px;
          color: #494a4b;
          text-align: left;
          display: flex;
          align-items: center;
          border-bottom: solid 1px $borderColor;

          & .icon {
            margin-right: 6px;
          }

          &:hover {
            background-color: #dbdbdb;
          }
        }
      }
    }
  }
  &__nav {
    z-index: 15;
    min-height: 54px;
    background-color: $backgroundColor;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 6px 6px 35px;
    position: relative;
    height: 60px;
    color: white;

    &__brand {
      position: absolute;
      top: 6px;
      left: 50%;
      & img {
        max-height: 2.5rem;
        position: relative;
        left: -50% !important;
      }
    }

    &__options {
      display: flex;

      &__create-account {
        position: relative;

        & > div {
          padding: 0.2rem 0.8rem 0;
          font-size: 0.9rem;
          background-color: #1a83f6;
          position: absolute;
          transform: skew(-25deg);
          bottom: -1rem;
          left: 1.6rem;
          box-shadow: 2px 2px 3px black;
          & > div {
            font-family: 'Avenir-Medium' !important;
            transform: skew(25deg);
            & > .amount {
              font-family: 'Avenir-Pro-Bold' !important;
              font-weight: bold !important;
            }
          }
        }
      }

      & a {
        margin: 0 5px;
        color: white;
        border-radius: 17px;
        border: solid 0.9px #ffffff;
        padding: 6px 18px;
        font-family: Circular-Std, sans-serif;
      }
    }

    &__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & div {
        margin: 0 4px;
      }

      &__text {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-family: Circular-Std;
      }

      &__hamburger {
        padding: 10px 12px;
        border-radius: 4px;

        &:hover {
          background-color: #4d4d4d;
        }

        & div {
          width: 16px;
          height: 1px;
          background-color: white;
          margin: 4px 0;
        }
      }
    }
  }
  &__nav {
    z-index: 15;
    min-height: 54px;
    background-color: $backgroundColor;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 6px 6px 35px;
    position: relative;
    height: 60px;
    color: white;

    &__brand {
      position: absolute;
      top: 6px;
      left: 50%;
      & img {
        max-height: 2.5rem;
        position: relative;
        left: -50% !important;
      }
    }

    &__options {
      display: flex;

      &__create-account {
        position: relative;

        & > div {
          padding: 0.2rem 0.8rem 0;
          font-size: 0.9rem;
          background-color: #1a83f6;
          position: absolute;
          transform: skew(-25deg);
          bottom: -1rem;
          left: 1.6rem;
          box-shadow: 2px 2px 3px black;
          & > div {
            font-family: 'Avenir-Medium' !important;
            transform: skew(25deg);
            & > .amount {
              font-family: 'Avenir-Pro-Bold' !important;
              font-weight: bold !important;
            }
          }
        }
      }

      & a {
        margin: 0 5px;
        color: white;
        border-radius: 17px;
        border: solid 0.9px #ffffff;
        padding: 6px 18px;
        font-family: Circular-Std, sans-serif;
      }
    }

    &__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & div {
        margin: 0 4px;
      }

      &__text {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-family: Circular-Std;
      }

      &__hamburger {
        padding: 10px 12px;
        border-radius: 4px;

        &:hover {
          background-color: #4d4d4d;
        }

        & div {
          width: 16px;
          height: 1px;
          background-color: white;
          margin: 4px 0;
        }
      }
    }
  }

  &__stadium {
    &__header {
      display: none;
      margin-top: 36px;
      height: 48px;
      background-color: $backgroundColor;
      position: relative;

      &__brand {
        position: absolute;
        top: 4px;
        left: 50%;
        & img {
          max-height: 2.5rem;
          position: relative;
          left: -50% !important;
        }
      }
    }

    &__image {
      width: 100%;
      background-color: black;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url('https://az755631.vo.msecnd.net/golstats-bets/header-desktop-stadium.jpg');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      object-position: center;
      object-fit: cover;
      @media screen and (max-width: 576px) {
        height: 140px;
        background-image: url('/images/header-mobile-stadium.png');
      }
      @media screen and (max-width: 410px) {
        height: 83px;
      }
      h4 {
        color: #ffffff;
        font-size: 42px;
        font-weight: 500;
        font-family: Circular-Std-Book, sans-serif;
        margin: 0 12px 0 0;
        text-shadow: -1px 1px 2px #000000;
        @media screen and (max-width: 500px) {
          font-size: 32px;
        }
        @media screen and (max-width: 410px) {
          font-size: 24px;
        }
      }
      span {
        font-family: Circular-Std-Bold, sans-serif;
      }
      img {
        height: 42px;
        @media screen and (max-width: 500px) {
          height: 32px;
        }
        @media screen and (max-width: 410px) {
          height: 24px;
        }
      }
    }
  }
}

a.btn {
  display: block;
  width: 100%;
  margin: 12px 0;
  padding: 10px 0;
  color: #3c3c3c;
  border: solid 2px #3c3c3c;
  border-radius: 28px;

  &.btn-login {
    position: relative;
    & span:first-child {
      margin-right: 20px;
    }
    & span:last-child {
      position: absolute;
      top: 2px;
      right: 5px;
    }
  }
}

.btn-create-account {
  position: relative;
  & > div {
    padding: 0.2rem 0.8rem 0;
    background-image: linear-gradient(to right, #ae0b0b, #e02020);
    position: absolute;
    transform: skew(-25deg);
    bottom: -14px;
    right: 60px;
    box-shadow: 2px 2px 3px black;

    & > div {
      color: white;
      font-size: 12px;
      font-family: 'Avenir-Medium' !important;
      transform: skew(25deg);
      & > .amount {
        font-family: 'Avenir-Pro-Bold' !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .navbar-free__nav {
    justify-content: space-between;

    &__brand {
      position: static;

      & img {
        left: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar-free__nav {
    &__options {
      &__create-account {
        position: relative;

        & > div {
          bottom: -0.8rem;
          left: 0.8rem;

          & > div {
            font-size: 0.6rem;
          }
        }
      }

      & a {
        padding: 4px 14px;
        font-size: 0.6rem;
      }
    }
  }

  .navbar-free__stadium {
    &__header {
      display: block;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.logo-team-flag {
  max-height: 1.7rem !important;
}
</style>
